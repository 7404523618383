import { AjaxRenderingEngine } from '@scripts/ajax-render-engine';
import '@scripts/ViewModels/variant/ajax-variant-selector.js';

export default new (function ($) {
    'use strict';

    this.vm = function (id) {
        var self = this;
        self.id = "#" + id;
 
        self.service = new WebApiService("FieldRenderer");        
        self.vs = new mcWeb.ajaxVariantSelector.vm(self);        
        self.avsInitialised = false;
        self.load = function(data, settings){                       
          
        };

        self.init = function(data){
            self.currentAreaName = data.areaName;            
            self.currentNodeId = data.nodeId;
            self.width = 400;
            if(self.id === '#lightbox-placeholder'){    
                self.popupEvent = mcWeb.lightbox.events.onShowLightbox;
                self.currentPlacement = "c";
            }
            else {
                self.popupEvent = mcWeb.slideOverBox.events.onShowLightbox;
                self.currentPlacement = "rhs";
            }
        };

        self.show = function( plid, manufacturerId) {
            var param = {
                NodeId: self.currentNodeId,
                Width: self.width,
                ManufacturerId: manufacturerId,
                ActivePlid: plid,
                Placement: self.currentPlacement,
                AreaName : self.currentAreaName                
            };

            self.service.PostAction("RenderQuickview", param).done(
                function(result) {
                    if (result != null && result.Success) {
                        var container = $(self.id);
                        if (container != null && container != undefined) {
                            container.html(result.Content);

                        AjaxRenderingEngine.applyCustomerSpecifics(container, result);
                          //todo: finn riktig klasse å bruke her. avhenger av hvilken popup?
                            //mangler å kjøre royal-slider for resultatet. Må gjøres for å få fram bilder osv.
                                var popupParam = {
                                    css: "d4-popup d4-popup-quickview",
                                    callback: function() {
                                        var thumbs = {//todo: get from settings?
                                            spacing: 10,
                                            orientation: 'horizontal',
                                            autoCenter: false
                                        };
                                        var navigationMethod = 'bullets';//todo: get from settings?
                                        InitializeRoyalSliderForPreview(null, null, null, 'div.d4-preview', thumbs,navigationMethod);
                                        self.vs.bind();
                                        self.vs.reinit();
                                    }
                                }
                                PubSub.publish(self.popupEvent, popupParam);
                        }
                    }
                })
        };
    };//end vm
})(jQuery);


////Bind viewmodel for two quickviews, one for center and one for rhs
//loadModule(mcWeb.quickview, 'slideover-placeholder', 'slideover-placeholder', null, null,true);
//loadModule(mcWeb.quickview, 'lightbox-placeholder', 'lightbox-placeholder', null, null,true);
